/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, {useEffect, useRef} from "react";
import {useGLTF, useAnimations} from "@react-three/drei";

export default function Model({...props}) {
  const group = useRef();
  const {nodes, materials, animations} = useGLTF(
    "https://firebasestorage.googleapis.com/v0/b/pig-project-ea5ab.appspot.com/o/earth%2FearthAntarctica.glb?alt=media&token=0b51df9f-e691-4152-87f8-178a74f63f95"
  );
  const {actions} = useAnimations(animations, group);
  useEffect(() => {
    actions.Animation2.play();
    actions.Animation2.repetitions = 1;
    actions.Animation2.clampWhenFinished = true;
  }, [actions]);
  return (
    <group ref={group} {...props} dispose={null}>
      <group>
        <mesh
          name="Antarctica"
          geometry={nodes.Antarctica.geometry}
          material={materials["材質.002"]}
          rotation={[-Math.PI, 1, -Math.PI]}
          scale={1.95}
        />
      </group>
    </group>
  );
}

useGLTF.preload(
  "https://firebasestorage.googleapis.com/v0/b/pig-project-ea5ab.appspot.com/o/earth%2FearthAntarctica.glb?alt=media&token=0b51df9f-e691-4152-87f8-178a74f63f95"
);
//finall finish CORS problem
