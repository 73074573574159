/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, {useEffect, useRef} from "react";
import {useGLTF, useAnimations} from "@react-three/drei";

export default function Model({...props}) {
  const group = useRef();
  const {nodes, materials, animations} = useGLTF(
    "https://firebasestorage.googleapis.com/v0/b/pig-project-ea5ab.appspot.com/o/Shoes%2FAnimAir.glb?alt=media&token=dc7d550c-6e33-4a66-8669-99cb0a0ebd64"
  );
  const {actions} = useAnimations(animations, group);
  useEffect(() => {
    // AnimationAction[0].play()
    // actions.Animation.AnimationAction.play()
    actions.Animation.play();
    actions.Animation.repetitions = 1;
    actions.Animation.clampWhenFinished = true;
  }, [actions]);
  return (
    <group scale={1.2} ref={group} {...props} dispose={null}>
      <mesh
        name="Air_cushion"
        geometry={nodes.Air_cushion.geometry}
        material={materials["Material.013"]}
        position={[-0.01, 1.34, -0.53]}
        scale={[0.24, 0.21, 0.24]}
      />
    </group>
  );
}

useGLTF.preload(
  "https://firebasestorage.googleapis.com/v0/b/pig-project-ea5ab.appspot.com/o/Shoes%2FAnimAir.glb?alt=media&token=dc7d550c-6e33-4a66-8669-99cb0a0ebd64"
);
