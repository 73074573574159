/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, {useRef, useEffect} from "react";
import {useGLTF, useAnimations} from "@react-three/drei";

export default function Model({...props}) {
  const group = useRef();
  const {nodes, materials, animations} = useGLTF(
    "https://firebasestorage.googleapis.com/v0/b/pig-project-ea5ab.appspot.com/o/treadmill%2FmillSupportShaft.glb?alt=media&token=353a20af-b008-4c86-8f75-a4b07ad1a8a8"
  );
  const {actions} = useAnimations(animations, group);
  useEffect(() => {
    actions.Animation15.play();
    actions.Animation15.repetitions = 1;
    actions.Animation15.clampWhenFinished = true;
  }, [actions]);
  return (
    <group ref={group} {...props} dispose={null}>
      <group>
        <mesh
          name="supportShaft"
          geometry={nodes.supportShaft.geometry}
          material={materials.軸灰}
        />
      </group>
    </group>
  );
}

useGLTF.preload(
  "https://firebasestorage.googleapis.com/v0/b/pig-project-ea5ab.appspot.com/o/treadmill%2FmillSupportShaft.glb?alt=media&token=353a20af-b008-4c86-8f75-a4b07ad1a8a8"
);
