/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, {useRef, useEffect} from "react";
import {useGLTF, useAnimations} from "@react-three/drei";

export default function Model({...props}) {
  const group = useRef();
  const {nodes, materials, animations} = useGLTF(
    "https://firebasestorage.googleapis.com/v0/b/pig-project-ea5ab.appspot.com/o/RubberTube%2FtubeYellow.glb?alt=media&token=30a9bf96-5322-490f-b982-c40d7287e51a"
  );
  const {actions} = useAnimations(animations, group);
  // console.log(actions)
  // useEffect(() => {
  //   actions.Animation9.play();
  //   actions.Animation9.repetitions = 1;
  //   actions.Animation9.clampWhenFinished = true;
  // }, [actions]);
  return (
    <group ref={group} {...props} dispose={null}>
      <group>
        <mesh
          name="yellow"
          geometry={nodes.yellow.geometry}
          material={materials["材質.001"]}
        />
      </group>
    </group>
  );
}

useGLTF.preload(
  "https://firebasestorage.googleapis.com/v0/b/pig-project-ea5ab.appspot.com/o/RubberTube%2FtubeYellow.glb?alt=media&token=30a9bf96-5322-490f-b982-c40d7287e51a"
);
