/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, {useRef, useEffect} from "react";
import {useGLTF, useAnimations} from "@react-three/drei";

export default function Model({...props}) {
  const group = useRef();
  const {nodes, materials, animations} = useGLTF(
    "https://firebasestorage.googleapis.com/v0/b/pig-project-ea5ab.appspot.com/o/Shoes%2FAnimPig3d2.glb?alt=media&token=5f8ea655-a2ca-4ac1-aeb6-9596cba6dd41"
  );
  const {actions} = useAnimations(animations, group);
  useEffect(() => {
    // AnimationAction[0].play()
    // actions.Animation.AnimationAction.play()
    actions.Animation.play();
    actions.Animation.repetitions = 1;
    actions.Animation.clampWhenFinished = true;
  }, [actions]);
  return (
    <group scale={1.2} ref={group} {...props} dispose={null}>
      <group
        name="PIG3D2"
        position={[-0.54, 2.62, -1.11]}
        rotation={[1.69, -0.32, -1.84]}
        scale={[0.1, 0.1, 0.1]}
      >
        <mesh
          geometry={nodes.Plane003.geometry}
          material={materials["Material.010"]}
        />
        <mesh
          geometry={nodes.Plane003_1.geometry}
          material={materials["Material.006"]}
        />
      </group>
    </group>
  );
}

useGLTF.preload(
  "https://firebasestorage.googleapis.com/v0/b/pig-project-ea5ab.appspot.com/o/Shoes%2FAnimPig3d2.glb?alt=media&token=5f8ea655-a2ca-4ac1-aeb6-9596cba6dd41"
);
