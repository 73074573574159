/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, {useRef, useEffect} from "react";
import {useGLTF, useAnimations} from "@react-three/drei";

export default function Model({...props}) {
  const group = useRef();
  const {nodes, materials, animations} = useGLTF(
    "https://firebasestorage.googleapis.com/v0/b/pig-project-ea5ab.appspot.com/o/massageGun%2FgunBottomCover.glb?alt=media&token=60a22181-ef9a-4152-9f45-7ccabac03c37"
  );
  const {actions} = useAnimations(animations, group);
  useEffect(() => {
    actions.Animation1.play();
    actions.Animation1.repetitions = 1;
    actions.Animation1.clampWhenFinished = true;
  }, [actions]);
  return (
    <group ref={group} {...props} dispose={null}>
      <group>
        <mesh
          name="bottomCover"
          geometry={nodes.bottomCover.geometry}
          material={materials.材質}
        />
      </group>
    </group>
  );
}

useGLTF.preload(
  "https://firebasestorage.googleapis.com/v0/b/pig-project-ea5ab.appspot.com/o/massageGun%2FgunBottomCover.glb?alt=media&token=60a22181-ef9a-4152-9f45-7ccabac03c37"
);
