/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef ,useEffect} from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export default function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('https://firebasestorage.googleapis.com/v0/b/pig-project-ea5ab.appspot.com/o/massageGun%2FgunHead1.glb?alt=media&token=ebf17724-f668-48e0-b238-db6f9f4319b6')
  const { actions } = useAnimations(animations, group)
  useEffect(() => {
    actions.Animation4.play();
    actions.Animation4.repetitions = 1;
    actions.Animation4.clampWhenFinished = true;
  }, [actions]);
  return (
    <group ref={group} {...props} dispose={null}>
      <group>
        <group name="head1">
          <mesh geometry={nodes.圓柱體011.geometry} material={materials['材質.006']} />
          <mesh geometry={nodes.圓柱體011_1.geometry} material={materials['材質.005']} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('https://firebasestorage.googleapis.com/v0/b/pig-project-ea5ab.appspot.com/o/massageGun%2FgunHead1.glb?alt=media&token=ebf17724-f668-48e0-b238-db6f9f4319b6')
