/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, {useRef, useEffect} from "react";
import {useGLTF, useAnimations} from "@react-three/drei";

export default function Model({...props}) {
  const group = useRef();
  const {nodes, materials, animations} = useGLTF(
    "https://firebasestorage.googleapis.com/v0/b/pig-project-ea5ab.appspot.com/o/GreenBike%2FbikeWheel.glb?alt=media&token=4ae4c424-a72b-4b14-84e2-e0b493e70367"
  );
  const {actions} = useAnimations(animations, group);
  useEffect(() => {
    actions.Animation11.play();
    actions.Animation11.repetitions = 1;
    actions.Animation11.clampWhenFinished = true;
  }, [actions]);
  return (
    <group ref={group} {...props} dispose={null}>
      <group>
        <group name="wheel">
          <mesh
            geometry={nodes.立方體002.geometry}
            material={materials["材質.007"]}
          />
          <mesh
            geometry={nodes.立方體002_1.geometry}
            material={materials["材質.011"]}
          />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload(
  "https://firebasestorage.googleapis.com/v0/b/pig-project-ea5ab.appspot.com/o/GreenBike%2FbikeWheel.glb?alt=media&token=4ae4c424-a72b-4b14-84e2-e0b493e70367"
);
