/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, {useEffect, useRef} from "react";
import {useGLTF, useAnimations} from "@react-three/drei";

export default function Model({...props}) {
  const group = useRef();
  const {nodes, materials, animations} = useGLTF(
    "https://firebasestorage.googleapis.com/v0/b/pig-project-ea5ab.appspot.com/o/earth%2FearthAmerica.glb?alt=media&token=f9074413-827c-4297-a6cd-38dcf7afebce"
  );
  const {actions} = useAnimations(animations, group);
  useEffect(() => {
    actions.Animation1.play();
    actions.Animation1.repetitions = 1;
    actions.Animation1.clampWhenFinished = true;
  }, [actions]);
  return (
    <group ref={group} {...props} dispose={null}>
      <group>
        <mesh
          name="America"
          geometry={nodes.America.geometry}
          material={materials["材質.002"]}
          rotation={[-Math.PI, 1, -Math.PI]}
          scale={1.95}
        />
      </group>
    </group>
  );
}

useGLTF.preload(
  "https://firebasestorage.googleapis.com/v0/b/pig-project-ea5ab.appspot.com/o/earth%2FearthAmerica.glb?alt=media&token=f9074413-827c-4297-a6cd-38dcf7afebce"
);
