/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, {useRef} from "react";
import {useGLTF} from "@react-three/drei";
import {useFrame} from "@react-three/fiber";

export default function Model({...props}) {
  const group = useRef();
  const {nodes, materials} = useGLTF(
    "https://firebasestorage.googleapis.com/v0/b/pig-project-ea5ab.appspot.com/o/Shoes%2FshoesPIGtwo.glb?alt=media&token=ce88676a-641a-4e6e-8807-3b732b341a6e"
  );
  useFrame(() => {
    group.current.rotation.y += 0.005;
  });
  return (
    <group
      scale={1.4}
      position={(0, 1, -0.3)}
      ref={group}
      {...props}
      rotation={[0, Math.PI / 0.8, 0]}
      dispose={null}
    >
      <mesh
        geometry={nodes.Air_cushion.geometry}
        material={materials["Material.013"]}
        // position={[-0.01, 0.98, -0.79]}
        position={[-0.01, 0.98, -0.85]}
        scale={[0.24, 0.21, 0.24]}
      />
      <mesh
        geometry={nodes.insole.geometry}
        material={materials["Material.011"]}
        // position={[-0.01, 1.34, -0.53]}
        position={[-0.01, 1.3, -0.53]}
        rotation={[0.15, 0, 0]}
        scale={[0.22, 0.19, 0.22]}
      />
      <mesh
        geometry={nodes.LOGO3D.geometry}
        material={materials["Material.009"]}
        position={[-0.07, 0.76, -1.1]}
        scale={[0.05, 0.06, 0.06]}
      />
      <mesh
        geometry={nodes.outsole.geometry}
        material={materials["Material.008"]}
        // position={[0.19, 0.07, 0.72]}
        position={[0.19, 0.07, 0.78]}
        rotation={[Math.PI / 2, -1.54, Math.PI / 2]}
        scale={0.16}
      />
      <mesh
        geometry={nodes.PIG3D.geometry}
        material={materials["Material.007"]}
        position={[0, 0.78, -0.96]}
        scale={0.04}
      />
      <mesh
        geometry={nodes.PIG3D2.geometry}
        material={materials["Material.010"]}
        position={[0.05, 1.48, -1.67]}
        rotation={[-Math.PI / 2, 1.57, 0]}
        scale={0.11}
      />
      <mesh
        geometry={nodes.rope.geometry}
        material={materials["Material.006"]}
        position={[0, 0.94, -1.68]}
        rotation={[1.64, 0, 0]}
        scale={0.08}
      />
      <mesh
        geometry={nodes.sock.geometry}
        material={materials["Material.004"]}
        // position={[0, 0.99, -0.82]}
        position={[0, 0.99, -0.81]}
        scale={[0.24, 0.24, 0.24]}
      />
      <mesh
        geometry={nodes.socktrim.geometry}
        material={materials["Material.002"]}
        // position={[-0.01, 0.99, -0.83]}
        position={[-0.01, 0.99, -0.8115]}
        scale={[0.24, 0.24, 0.24]}
      />
      <mesh
        geometry={nodes.socktrimOnShoes.geometry}
        material={materials["Material.003"]}
        position={[0, 0.99, -0.82]}
        scale={[0.24, 0.24, 0.24]}
      />
      <mesh
        geometry={nodes.sole.geometry}
        material={materials["Material.005"]}
        // position={[-0.01, 0.98, -0.79]}
        position={[-0.01, 0.98, -0.75]}
        scale={[0.24, 0.21, 0.24]}
      />
    </group>
  );
}

useGLTF.preload(
  "https://firebasestorage.googleapis.com/v0/b/pig-project-ea5ab.appspot.com/o/Shoes%2FshoesPIGtwo.glb?alt=media&token=ce88676a-641a-4e6e-8807-3b732b341a6e"
);
