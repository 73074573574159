/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, {useRef, useEffect} from "react";
import {useGLTF, useAnimations} from "@react-three/drei";

export default function Model({...props}) {
  const group = useRef();
  const {nodes, materials, animations} = useGLTF(
    "https://firebasestorage.googleapis.com/v0/b/pig-project-ea5ab.appspot.com/o/RubberTube%2FtubeBlue.glb?alt=media&token=b13c2a6c-27a7-4056-8cc4-0cfd03e9e460"
  );
  const {actions} = useAnimations(animations, group);
  useEffect(() => {
    actions.Animation1.play();
    actions.Animation1.repetitions = 1;
    actions.Animation1.clampWhenFinished = true;
  }, [actions]);
  return (
    <group ref={group} {...props} dispose={null}>
      <group>
        <group name="blue">
          <mesh
            geometry={nodes.貝茲曲線003.geometry}
            material={materials["材質.005"]}
          />
          <mesh
            geometry={nodes.貝茲曲線003_1.geometry}
            material={materials.材質}
          />
          <mesh
            geometry={nodes.貝茲曲線003_2.geometry}
            material={materials["材質.004"]}
          />
          <mesh
            geometry={nodes.貝茲曲線003_3.geometry}
            material={materials["材質.002"]}
          />
          <mesh
            geometry={nodes.貝茲曲線003_4.geometry}
            material={materials.rubber}
          />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload(
  "https://firebasestorage.googleapis.com/v0/b/pig-project-ea5ab.appspot.com/o/RubberTube%2FtubeBlue.glb?alt=media&token=b13c2a6c-27a7-4056-8cc4-0cfd03e9e460"
);
