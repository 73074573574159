/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, {useRef} from "react";
import {useGLTF} from "@react-three/drei";
import {useFrame} from "@react-three/fiber";

export default function Model({...props}) {
  useFrame(() => {
    group.current.rotation.y += 0.005;
  });
  const group = useRef();
  const {nodes, materials} = useGLTF(
    "https://firebasestorage.googleapis.com/v0/b/pig-project-ea5ab.appspot.com/o/treadmill%2Ftreadmill.glb?alt=media&token=6be506f6-9e68-499e-9624-b43f89505dc0"
  );
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh geometry={nodes.bracket.geometry} material={materials.霧面黑} />
      <mesh geometry={nodes.spring.geometry} material={materials.彈黃} />
      <mesh geometry={nodes.movementAxis.geometry} material={materials.軸白} />
      <mesh
        geometry={nodes.movementIronSheets.geometry}
        material={materials.金屬片}
      />
      <mesh geometry={nodes.springShaft.geometry} material={materials.軸灰} />
      <mesh geometry={nodes.supportShaft.geometry} material={materials.軸灰} />
      <mesh
        geometry={nodes.bottomBracket.geometry}
        material={materials.霧面黑}
      />
      <mesh geometry={nodes.supportPlate.geometry} material={materials.淺底} />
      <mesh geometry={nodes.立方體007.geometry} material={materials.材質} />
      <mesh
        geometry={nodes.立方體007_1.geometry}
        material={materials["材質.003"]}
      />
      <mesh geometry={nodes.armrest.geometry} material={materials.黑泡棉} />
      <mesh
        geometry={nodes.bottomShell.geometry}
        material={materials["材質.001"]}
      />
      <mesh geometry={nodes.wheel.geometry} material={materials.午夜藍} />
      <mesh geometry={nodes.ironSheets.geometry} material={materials.金屬銀} />
      <mesh geometry={nodes.panel.geometry} material={materials.面板} />
      <mesh
        geometry={nodes.panelShell.geometry}
        material={materials["材質.002"]}
      />
      <mesh
        geometry={nodes.圓柱體001.geometry}
        material={materials["材質.005"]}
      />
      <mesh geometry={nodes.圓柱體001_1.geometry} material={materials.跑帶} />
    </group>
  );
}

useGLTF.preload(
  "https://firebasestorage.googleapis.com/v0/b/pig-project-ea5ab.appspot.com/o/treadmill%2Ftreadmill.glb?alt=media&token=6be506f6-9e68-499e-9624-b43f89505dc0"
);
