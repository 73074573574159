/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, {useRef} from "react";
import {useGLTF} from "@react-three/drei";

export default function Model({...props}) {
  const group = useRef();
  const {nodes, materials} = useGLTF(
    "https://firebasestorage.googleapis.com/v0/b/pig-project-ea5ab.appspot.com/o/GreenBike%2FbikeBody.glb?alt=media&token=3f2230d2-be48-4bbc-8060-278b056d6eb2"
  );
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh geometry={nodes.body.geometry} material={materials["材質.003"]} />
    </group>
  );
}

useGLTF.preload(
  "https://firebasestorage.googleapis.com/v0/b/pig-project-ea5ab.appspot.com/o/GreenBike%2FbikeBody.glb?alt=media&token=3f2230d2-be48-4bbc-8060-278b056d6eb2"
);
