/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, {useRef, useEffect} from "react";
import {useGLTF, useAnimations} from "@react-three/drei";

export default function Model({...props}) {
  const group = useRef();
  const {nodes, materials, animations} = useGLTF(
    "https://firebasestorage.googleapis.com/v0/b/pig-project-ea5ab.appspot.com/o/Shoes%2FAnimSockTrimLogo.glb?alt=media&token=6d1f1a2a-e9a9-4619-aedc-485906857b95"
  );
  const {actions} = useAnimations(animations, group);
  useEffect(() => {
    // AnimationAction[0].play()
    // actions.Animation.AnimationAction.play()
    actions.Animation.play();
    actions.Animation.repetitions = 1;
    actions.Animation.clampWhenFinished = true;
  }, [actions]);
  return (
    <group scale={1.2} ref={group} {...props} dispose={null}>
      <mesh
        name="socktrimOnShoes"
        geometry={nodes.socktrimOnShoes.geometry}
        material={materials["Material.003"]}
        position={[1.18, 2.54, 0.93]}
        rotation={[1.14, 0.38, -0.61]}
        scale={[0.21, 0.21, 0.21]}
      />
    </group>
  );
}

useGLTF.preload(
  "https://firebasestorage.googleapis.com/v0/b/pig-project-ea5ab.appspot.com/o/Shoes%2FAnimSockTrimLogo.glb?alt=media&token=6d1f1a2a-e9a9-4619-aedc-485906857b95"
);
