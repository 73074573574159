/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, {useRef, useEffect} from "react";
import {useGLTF, useAnimations} from "@react-three/drei";

export default function Model({...props}) {
  const group = useRef();
  const {nodes, materials, animations} = useGLTF(
    "https://firebasestorage.googleapis.com/v0/b/pig-project-ea5ab.appspot.com/o/treadmill%2FmillMovementIronSheets.glb?alt=media&token=3933748f-793d-42b1-ab85-e32c52e69654"
  );
  const {actions} = useAnimations(animations, group);
  useEffect(() => {
    actions.Animation7.play();
    actions.Animation7.repetitions = 1;
    actions.Animation7.clampWhenFinished = true;
  }, [actions]);
  return (
    <group ref={group} {...props} dispose={null}>
      <group>
        <mesh
          name="movementIronSheets"
          geometry={nodes.movementIronSheets.geometry}
          material={materials.金屬片}
        />
      </group>
    </group>
  );
}

useGLTF.preload(
  "https://firebasestorage.googleapis.com/v0/b/pig-project-ea5ab.appspot.com/o/treadmill%2FmillMovementIronSheets.glb?alt=media&token=3933748f-793d-42b1-ab85-e32c52e69654"
);
