/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, {useRef, useEffect} from "react";
import {useGLTF, useAnimations} from "@react-three/drei";

export default function Model({...props}) {
  const group = useRef();
  const {nodes, materials, animations} = useGLTF(
    "https://firebasestorage.googleapis.com/v0/b/pig-project-ea5ab.appspot.com/o/RubberTube%2FtubeRope1.glb?alt=media&token=e8c01d0e-e1a7-4095-a45c-66460edbbd07"
  );
  const {actions} = useAnimations(animations, group);
  useEffect(() => {
    actions.Animation5.play();
    actions.Animation5.repetitions = 1;
    actions.Animation5.clampWhenFinished = true;
  }, [actions]);
  return (
    <group ref={group} {...props} dispose={null}>
      <group>
        <mesh
          name="rope1"
          geometry={nodes.rope1.geometry}
          material={materials.材質}
        />
      </group>
    </group>
  );
}

useGLTF.preload(
  "https://firebasestorage.googleapis.com/v0/b/pig-project-ea5ab.appspot.com/o/RubberTube%2FtubeRope1.glb?alt=media&token=e8c01d0e-e1a7-4095-a45c-66460edbbd07"
);
