/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, {useRef, useEffect} from "react";
import {useGLTF, useAnimations} from "@react-three/drei";

export default function Model({...props}) {
  const group = useRef();
  const {nodes, materials, animations} = useGLTF(
    "https://firebasestorage.googleapis.com/v0/b/pig-project-ea5ab.appspot.com/o/RubberTube%2FtubeRed.glb?alt=media&token=b5109aca-3c23-4451-8dcc-3eb7b720070d"
  );
  const {actions} = useAnimations(animations, group);
  useEffect(() => {
    actions.Animation4.play();
    actions.Animation4.repetitions = 1;
    actions.Animation4.clampWhenFinished = true;
  }, [actions]);
  return (
    <group ref={group} {...props} dispose={null}>
      <group>
        <group name="red">
          <mesh
            geometry={nodes.貝茲曲線002.geometry}
            material={materials["材質.003"]}
          />
          <mesh
            geometry={nodes.貝茲曲線002_1.geometry}
            material={materials.材質}
          />
          <mesh
            geometry={nodes.貝茲曲線002_2.geometry}
            material={materials["材質.004"]}
          />
          <mesh
            geometry={nodes.貝茲曲線002_3.geometry}
            material={materials["材質.002"]}
          />
          <mesh
            geometry={nodes.貝茲曲線002_4.geometry}
            material={materials.rubber}
          />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload(
  "https://firebasestorage.googleapis.com/v0/b/pig-project-ea5ab.appspot.com/o/RubberTube%2FtubeRed.glb?alt=media&token=b5109aca-3c23-4451-8dcc-3eb7b720070d"
);
