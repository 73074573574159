/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, {useRef, useEffect} from "react";
import {useGLTF, useAnimations} from "@react-three/drei";

export default function Model({...props}) {
  const group = useRef();
  const {nodes, materials, animations} = useGLTF(
    "https://firebasestorage.googleapis.com/v0/b/pig-project-ea5ab.appspot.com/o/treadmill%2FmillArmrest.glb?alt=media&token=63ccb14f-e20e-4a42-b603-eceb04f3996c"
  );
  const {actions} = useAnimations(animations, group);
  useEffect(() => {
    actions.Animation1.play();
    actions.Animation1.repetitions = 1;
    actions.Animation1.clampWhenFinished = true;
  }, [actions]);
  return (
    <group ref={group} {...props} dispose={null}>
      <group>
        <mesh
          name="armrest"
          geometry={nodes.armrest.geometry}
          material={materials.黑泡棉}
        />
      </group>
    </group>
  );
}

useGLTF.preload(
  "https://firebasestorage.googleapis.com/v0/b/pig-project-ea5ab.appspot.com/o/treadmill%2FmillArmrest.glb?alt=media&token=63ccb14f-e20e-4a42-b603-eceb04f3996c"
);
